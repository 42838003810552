

/* #region FONT CLASSES                          */
/* use 'npm i @fontsource/<fontname>' to install fonts... */
/* ...then import '@fontsource/<fontname>' */
.font-sans  { font-family: lato, montserrat, nunito, sans-serif; }                                                                  
.font-serif { font-family: lora, merriweather, serif; }                                                                  
.font-mono  { font-family: 'fira code', 'cascadia code', monospace; }                                                                  
.font-display { font-family: 'concert one', 'lobster two';}

.font-sans-lato    { font-family: 'Lato';        }
.font-sans-mont    { font-family: 'Montserrat';  }
.font-sans-nuni    { font-family: 'NunitoVariable';      }
.font-serif-lora   { font-family: 'LoraVariable';        }
.font-serif-merr   { font-family: 'Merriweather';}
.font-display-conc { font-family: 'Concert One'; }
.font-display-lobs { font-family: 'Lobster Two'; }
/* #endregion */

/* #region root app variables                                 */
:root {
  --color-txt  : rgba(245, 250, 255, 0.902);
  --color-bg   : #282c34;
  --color-shade: #11111155;
  --color-a    : #61dafbe6;
  --color-a1   : #8fe7ffe6;
  --color-a2   : #64a1cc;
  --color-a-hov: #ffffff22;
  --color-a-foc: #61dafb22;
  --color-none : #00000000;
  
  --font-header: Nunito, 'Segoe UI', sans-serif;;
  --font-body  : Montserrat, 'Segoe UI', sans-serif;
  --font-footer: 'Concert One';
  --font-code  : 'Fira Code', 'Cascadia Code', monospace;
  
  --font-sans-lato   : 'Lato';
  --font-sans-mont   : 'Montserrat';
  --font-sans-nuni   : 'NunitoVariable';
  --font-serif-lora  : 'LoraVariable';
  --font-serif-merr  : 'Merriweather';
  --font-display-conc: 'Concert One';
  --font-display-lobs: 'Lobster Two';
  
  --fontsize-main: calc(10px + 2vmin);
  
  --hf-curve-size: 2rem;
  --hf-curve-radius: 100%;
  
  --themePrimary         : #17c2e8;  /*  */
  --themeLighterAlt      : #010809;  /*  */
  --themeLighter         : #041f25;  /*  */
  --themeLight           : #073a46;  /*  */
  --themeTertiary        : #0e748b;  /*  */
  --themeSecondary       : #14abcc;  /*  */
  --themeDarkAlt         : #2dc8ea;  /*  */
  --themeDark            : #4bd0ee;  /*  */
  --themeDarker          : #78dcf2;  /*  */
  --neutralLighterAlt    : #262a32;  /*  */
  --neutralLighter       : #23262e;  /* // default hover  */
  --neutralLight         : #1f2228;  /* // default focus  */
  --neutralQuaternaryAlt : #22252c;  /*  */
  --neutralQuaternary    : #20232a;  /*  */
  --neutralTertiaryAlt   : #1f2228;  /*  */
  --neutralTertiary      : #a4a7ab;  /*  */
  --neutralSecondary     : #898c8f;  /*  */
  --neutralPrimaryAlt    : #6e7073;  /*  */
  --neutralPrimary       : #f5faff;  /*  */
  --neutralDark          : #e0f0ff;  /*  */
  --black                : #1d1e1f;  /*  */
  --white                : #282c34;  /*  */

}
/* #endregion */

/* #region UTILITYS                                      */
.shade { background-color: var(--color-shade); }
.blur  {backdrop-filter: blur(12px);}
@keyframes spinner { from { transform: rotate(0deg);   }
                      to   { transform: rotate(359deg); } }
.mp0 {margin:0; padding:0;}
.flexrc {display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center; align-content: center;}
.flexcc {display: flex; flex-direction: column; align-items: center; justify-content: center;}
.justify-fs { justify-content: flex-start;}
.mr2{ margin-right:2px;  }
.mr4{ margin-right:4px;  }
.mr6{ margin-right:6px;  }
.mr8{ margin-right:8px;  }
.ml2{ margin-left:2px;   }
.ml4{ margin-left:4px;   }
.ml6{ margin-left:6px;   }
.ml8{ margin-left:8px;   }
.mt2{ margin-top:2px;    }
.mt4{ margin-top:4px;    }
.mt6{ margin-top:6px;    }
.mt8{ margin-top:8px;    }
.mb2{ margin-bottom:2px; }
.mb4{ margin-bottom:4px; }
.mb6{ margin-bottom:6px; }
.mb8{ margin-bottom:8px; }

.pr2{ padding-right:2px;  }
.pr4{ padding-right:4px;  }
.pr6{ padding-right:6px;  }
.pr8{ padding-right:8px;  }
.pl2{ padding-left:2px;   }
.pl4{ padding-left:4px;   }
.pl6{ padding-left:6px;   }
.pl8{ padding-left:8px;   }
.pt2{ padding-top:2px;    }
.pt4{ padding-top:4px;    }
.pt6{ padding-top:6px;    }
.pt8{ padding-top:8px;    }
.pb2{ padding-bottom:2px; }
.pb4{ padding-bottom:4px; }
.pb6{ padding-bottom:6px; }
.pb8{ padding-bottom:8px; }
.w10{width:10rem;}
.w20{width:20rem;}
/* #endregion */

/* #region SHARED STYLES                                 */
body, footer, p, h1,h2,h3,h4,h5,h6, code, li, .header { color: var(--color-txt)}
/* #endregion */


.App { text-align: center; display: flex; flex-direction: column; height: 100vh; width: 100vw; margin:0; padding:0; }
body { margin: 0; font-family: var(--font-body); -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; background-color: var(--color-bg);}

@media (prefers-reduced-motion: no-preference) {
  .spinner {animation: spinner infinite 33s cubic-bezier(0.68, -0.55, 0.27, 1.55);}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
